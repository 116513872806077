.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.4rem 0;
  margin-bottom: 2rem;
}
.circleTop{
	width: 80%;
	margin: auto;
	text-align: center;
}
.circleInner{
	width: 25vw;
	height: 25vw;
	border-radius: 50%;
	background: linear-gradient(#4682a2, #7db7d7);
	display: inline-block;
	line-height: 25vw;
	vertical-align: middle;
	position: relative;
}
.circleInner:after{
	content: "";
	width: 70%;
	  height: 70%;
	  transform: translateY(-50%) translateX(-50%);
	  background: white;
	  display: block;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  border-radius: 50%;
}
.circleTop{
	position: static;
}
.circleInner img{
	width: 40%;
	height: 40%;
	line-height: 50vw;
	vertical-align: middle;
	position: relative;
	z-index: 3;
}
.circleTop li{
	list-style: none;
	align-items: center;
  flex: 1;
  vertical-align: middle;
  display: flex;
  position: relative;
  width: 80%;
  font-size: 1rem;
}
.circleTop li div{
	cursor: pointer;
}
.circleTop li:after{
	content: "";
	width: 100%;
	top:50%;
	position: absolute;
	border-bottom: 2px dotted;
  transform: translateY(14px);

}
.leftCirc{
	width: 50%;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.rightCirc{
	width: 50%;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: right;
}
.rightCirc li{
	justify-content: end;
}
.modalRes{
	position: absolute;
	width: 70vw;
	height:70vh;
	background:white;
	border-radius: 1rem;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 0 1px 5px;
  padding: 2%;
  display: none;
}
.modalRes .button{
	position: absolute;
	right: 10px;
	top:10px;
	background: transparent;
	border:0px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	color: #4682a2;
	font-size: 18px;
}
.modalRes p{
	margin: 0;
	text-align: left;
}
.fileWrap{
	padding: 4%;
  margin-top: 3%;
  border: 1px dashed #ccc;
}
.jobBoxes{
	display: flex;
	flex-wrap: wrap;
}
.circleTop h1{
	margin-bottom: 1rem;
}
.upload{
	border-radius: 0;
	padding: 0.3rem 0.5rem;
	border:0px;
	cursor: pointer;
}
.jobBoxes dl{
	border-radius: 3px;
	padding: 2%;
	background: #e4efef;
	cursor: pointer;
	margin: 0 1.5% 1.5% 0;
    width: 30%;
    font-size: 1rem;
    font-weight: bold;
}
.modalRes .lbAr p{
	margin: 0.5rem 0;
	font-weight: bold;
}
.jobBoxes .jd{
	display: none;
}
.raws{
	display: flex;
	height: 100%;
}
.raws .lbAr , .raws .rbAr{
	flex: 1;
	text-align: left;
	overflow-y: scroll;
	height: 90%;
}
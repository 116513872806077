.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.4rem 0;
  margin-bottom: 2rem;
}
.sectionAbout{
	width: 80%;
  margin: auto;
  background: #4682a2;
  padding: 1%;
  border-radius: 1rem;
  margin-bottom: 1%;
  color:white;
}
.sectionAbout:nth-child(2n){
	background: #7db7d7;
}
.sectionAbout:nth-child(2n+1){
	text-align: right;
}
.sectionAbout header{
	font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
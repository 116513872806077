:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: #e4efef;
  color: #7db7d7;

}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: light;
  }
}
.modalResr.shown{
  display: block !important;
}
.app_body{
  background: white;
  width:calc(100% - 100px);
  margin: 50px;
  display: block;
  border-radius: 50px;
  min-height: calc(100vh - 100px);
  position: relative;
  font-size: 0.8rem;
  height: 100%;
}
.app_body nav{
  display: block;
  margin:auto;
  text-align: center;
}
.app_body nav ul{
  list-style: none;
  margin: 20px auto;
  display: block;
  left:50%;
  transform: translateX(-50%);
  width: 28.35rem;
  line-height: 2.5rem;
  background: #7db7d7;
  border-radius: 2.5rem;
  position: absolute;
}
.app_body nav ul li{
  display: inline-block;
  width: 5.5rem;
  text-align: center;
}
.app_body .active{
  background: #049792;
  line-height: 1.8rem;
  border-radius: 2.5rem;
  color: white;
}
.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection h3{
  font-size: 1.6rem;
  color: #265c7a;
  font-family: Ubuntu;
  margin: 1.2rem 0;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.6rem 0;
}
.homebtn{
  margin-top:0.6rem;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  border: 0;
  background: #4682a2;
  color: white;
}
.section{
  width: 80%;
  margin:auto;
  display: flex;
}
.section > div{
  background: #7db7d7;
  flex: 1;
  margin: 2%;
  border-radius: 2rem;
  padding: 2%;
}
.section > div:nth-child(2){
  background: #4682a2;
}
.section > div header{
  color: white;
  font-size: 1rem;
  margin-top:0.5rem;
}
.divsec div{
 color:white;
 margin:1rem 0;
}
.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.4rem 0;
  margin-bottom: 2rem;
}
.sections{
	display: flex;
	width: 80%;
	margin: auto;
}
.sections .lBar,.sections .rBar{
	flex: 1 1;
  height: 20vw;
  padding: 2%;
  position: relative;
}
.sections .lBar img,.sections .lBar iframe{
	width:20vw;
	height: 20vw;
	position: absolute;
	border:0;
	left: 50%;
	top:50%;
	transform: translateY(-50%) translateX(-50%);
}
.sections .rBar{
	border-left:1px dashed #7db7d7;
	text-align: left;
}
.sections .rBar p{
	font-size: .9rem;
  text-align: left;
  transform: translateY(-50%);
  margin: 0;
  position: absolute;
  top: 50%;
}
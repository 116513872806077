.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.4rem 0;
}
.sectionbox{
	width: 80%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
}
.serveBox{
	flex: 1;
	border-radius: 1rem;
	color: white;
	background: #4682a2;
	margin: 3%;
	padding: 1%;
}
.serveBox:nth-child(2n){
 background: #7db7d7;
}
 @media only screen and (max-width: 600px) {

.break{
	flex-basis: 100%;
  height: 0;
}
}
.serveBox .title{
 font-size: 1rem;
 font-weight: bold;
 margin-bottom: 0.2rem;
  border-bottom: 1px solid;
}
.serveBox .ulli li{
	list-style: none;
	margin: 0.8rem 0;
	font-size: 0.9rem;
}
.app_body{
  background: white;
  width:calc(100% - 100px);
  margin: 50px;
  display: block;
  border-radius: 50px;
  min-height: calc(100vh - 100px);
  position: relative;
  height: 100%;
  font-size: 0.9rem;
}
.app_body nav{
  display: block;
  margin:auto;
  text-align: center;
}
.app_body nav ul{
  list-style: none;
  margin: 20px auto;
  display: block;
  left:50%;
  transform: translateX(-50%);
  width: 28.35rem;
  line-height: 2.5rem;
  background: #7db7d7;
  border-radius: 2.5rem;
  position: absolute;
}
.app_body nav ul li{
  display: inline-block;
  width: 5.5rem;
  text-align: center;
}
.app_body .active{
  background: #049792;
  line-height: 1.8rem;
  border-radius: 2.5rem;
  color: white;
}
.herosection{
  width: 39rem;
  text-align: center;
  margin: 0rem auto;
  padding: 5.5rem 0 0;
}
.herosection h1{
  font-size: 2.6rem;
  color: #265c7a;
  font-family: Ubuntu;
}
.herosection h3{
  font-size: 1.6rem;
  color: #265c7a;
  font-family: Ubuntu;
  margin: 1.2rem 0;
}
.herosection div{
  font-size: 0.95rem;
  margin: 0.6rem 0;
}
.homebtn{
  margin-top:0.6rem;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  border: 0;
  background: #4682a2;
  color: white;
}
.section{
  width: 80%;
  margin:auto;
  display: flex;
}
.section > div{
  background: #7db7d7;
  flex: 1;
  margin: 2%;
  border-radius: 2rem;
  padding: 2%;
}
.section > div:nth-child(2){
  background: #4682a2;
}
.section > div header{
  color: white;
  font-size: 1rem;
  margin-top:0.5rem;
}
.divsec div{
 color:white;
 margin:1rem 0;
}